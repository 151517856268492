/*
 *  Device Menu
 *  Creates FlavourWall Menu layout for 2D elements
 */

import Devices from "./devices";
import gsap from "gsap";
import CSSPlugin from "gsap/CSSPlugin";

export default class DeviceMenu
{
	constructor (manager)
	{
		this.stateName = "DEVICE_MENU";
		this.stateMngr = manager.state;

		this.enabled = false;
		this.active = false;

		this.devicesView = new Devices(manager);

		//Encourage GPU animations
		gsap.registerPlugin(CSSPlugin);

		//Register with state manager
		manager.state.registerState(this.stateName);
		manager.state.addStateChangeListener((to, from) => this.changeState(to, from));
	}

	init (pageJson, devices)
	{
		this.devicesView.init(devices);

		this.createElements(pageJson, devices);
	}

	changeState(to, from)
	{
		if (to == this.stateName && from == this.stateMngr.states.FLAVOURS_VIEW) this.show(0.6);
		else if (to == this.stateName) this.show(1.4);
		else this.hide();

		this.devicesView.changeState(to, from);
	}

	show (delay = 1.4)
	{
		this.active = true;

		gsap.to(this.titleElement, {
			duration: 1,
			autoAlpha: 1,
			delay: 1
		});

		gsap.to(this.devicesElement.childNodes, {
			duration: 1,
			autoAlpha: 1,
			stagger: 0.5,
			delay: delay,
			onComplete: () =>
			{
				this.enabled = true;
			}
		});
	}

	showSpecial (deviceObj)
	{
		this.devicesView.showSpecial(deviceObj);
	}

	hide ()
	{
		this.enabled = false;

		gsap.to(this.titleElement, {
			duration: 1,
			autoAlpha: 0
		});

		gsap.to(this.devicesElement.childNodes, {
			duration: 1,
			autoAlpha: 0,
			onComplete: () =>
			{
				this.active = false;
			}
		});
	}

	onTouch (evt)
	{
		evt.stopPropagation();
		if (!this.enabled) return;
		if (!this.active) return;

		this.stateMngr.publishEvent("changeDevice", {"deviceId": evt.target.id, "brandId": "vype"});
		this.stateMngr.publishEvent("analytics", {"event": "product", "data": evt.target.id});
		this.stateMngr.changeState(this.stateMngr.states.FLAVOURS_VIEW);
		this.enabled = false;
	}


	createElements (pageJson, devices)
	{
		const root = document.querySelector("#bat_UI");

		this.titleElement = document.createElement("h1");
		this.titleElement.innerHTML = pageJson.title.replaceAll("\n", "<br>");
		this.titleElement.style.visibility = "hidden";
		this.titleElement.style.opacity = 0;
		root.appendChild(this.titleElement);

		this.devicesElement = document.createElement("div");
		this.devicesElement.className = "devices";
		root.appendChild(this.devicesElement);

		let dev, ttl, sub, num, fla, c1, c2;

		for (let i = 0; i < devices.length; i++)
		{
			dev = document.createElement("div");
			dev.classList.add("device");
			dev.style.visibility = "hidden";
			dev.style.opacity = 0;
			dev.id = devices[i].id;

			//Mark number of devices for custom layouts
			if (devices.length === 1) dev.classList.add("one");
			if (devices.length === 2) dev.classList.add("three");
			if (devices.length === 3) dev.classList.add("three");
			if (devices.length === 4) dev.classList.add("four");

			ttl = document.createElement("h2");
			ttl.innerHTML = devices[i].name.replace("\n", "<br>");
			dev.appendChild(ttl);

			sub = document.createElement("div");
			sub.classList.add("subtitle");
			sub.innerHTML = devices[i].subtitle.replace("\n", "<br>");
			dev.appendChild(sub);

			c1 = devices[i].number_color1;
			c2 = devices[i].number_color2;

			num = document.createElement("div");
			num.classList.add("flav_num");
			num.innerHTML = devices[i].flavours.length;
			num.style.cssText = "background: -webkit-linear-gradient(" + c1 + "," + c2 + "); -webkit-background-clip: text; -webkit-text-fill-color: transparent;";
			dev.appendChild(num);

			fla = document.createElement("div");
			fla.classList.add("flav");
			fla.innerHTML = pageJson.flavours;
			dev.appendChild(fla);

			this.devicesElement.appendChild(dev);

			//add touch listener
			dev.addEventListener("mousedown", evt => this.onTouch(evt), false);
		}
	}
}
