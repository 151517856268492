/*
 *  AttractorDisc
 *  Use this as an object to manage attractor disc position & movement
 */

import * as THREE from "three";
import gsap from "gsap";
import Math2 from "./utils/math2"

export default class AttractorDisc extends THREE.Object3D
{
	constructor (id, start, count, cam)
	{
		super();

		this.discId = id;
		this.startDepth = start;
		this.endDepth = cam.position.z;
		this.gapDist = Math.abs(this.startDepth) + Math.abs(this.endDepth);
		this.gap = this.gapDist / count;
		this.last = Date.now();

		let aa = (100 * id) + Math2.randomRange(-10, 10);
		aa = Math2.deg2rad * aa;
		const xx = Math.sin(aa) * Math2.randomRange(4, 6);
		const yy = Math.cos(aa) * Math2.randomRange(4, 5);
		const zz = id * this.gap;

		this.start = new THREE.Vector3(xx * 80, yy * 80, this.startDepth);
		this.speed = new THREE.Vector3(xx * -.0000006, yy * -.0000005, 1000);
		this.path = new THREE.Ray(this.start, this.speed.normalize());

		//Apply start transform
		this.scale.set(2, 2, 2);
		this.position.copy(this.start);
		this.position.z -= zz;
		this.rotateZ(-aa);
		this.rotateX(Math.random() * Math.PI * 2);

		// const axesHelper = new THREE.AxesHelper( 50 );
		// this.add( axesHelper );

		this.mainColor = new THREE.Color();
		this.discColor = "#ffffff";
		this.grayColor = "#888888";
		this.tempColor = "#000000";
		this.colorFlag = false;

		this.opacity = 0;
		this.opacityFlag = false;
		this.reachedEnd = false;
	}

	prewarm ()
	{
		const dist = this.discId * this.gap;
		const v = new THREE.Vector3();
		this.path.at(dist, v);
		this.position.copy(v);
		this.reachedEnd = false;
	}

	sendToBack (newCol)
	{
		this.position.set(
			this.start.x,
			this.start.y,
			this.position.z - this.gapDist
		);

		this.setColor(newCol);

		this.opacity = 0;
		this.opacityFlag = true;

		//Fade in
		gsap.killTweensOf(this);
		gsap.to(this, {
			duration: 1,
			opacity: 1,
			onUpdate: () =>
			{
				this.opacityFlag = true;
			}
		});

		this.reachedEnd = false;
	}

	setColor (col)
	{
		this.discColor = col;
		this.mainColor.set(this.discColor);
		this.colorFlag = true;
	}

	getRed ()
	{
		// console.log(this.mainColor)
		this.colorFlag = false;
		return this.mainColor.r;
	}

	getGreen ()
	{
		this.colorFlag = false;
		return this.mainColor.g;
	}

	getBlue ()
	{
		this.colorFlag = false;
		return this.mainColor.b;
	}

	getOpacity ()
	{
		this.opacityFlag = false;
		return this.opacity;
	}

	hide ()
	{
		const tPos = new THREE.Vector3(-1, 0, 0); //Left
		tPos.applyQuaternion(this.quaternion); //Local.Left
		tPos.cross(this.path.direction);
		tPos.multiplyScalar(300);
		tPos.add(this.position);

		gsap.to(this.position, {duration: 1, x: tPos.x, y: tPos.y, z: tPos.z});

		const r = -Math2.randomRange(0.05, 0.15);

		gsap.to(this, {
			duration: 1,
			onUpdate: () => {
				this.rotateX(r);
			}
		});
	}

	animate ()
	{
		const now = Date.now();
		const delta = (now - this.last) * 0.06;
		this.last = now;

		//rotate disc towards camera
		this.position.addScaledVector(this.speed, delta);
		this.rotateX(-0.005 * delta);

		//fade out at end
		if (this.position.z > this.endDepth - 100)
		{
			let a = (this.endDepth - this.position.z) * 0.01;
			a = Math.min(Math.max(a, 0), 1);
			this.opacity = a;
			this.opacityFlag = true;
		}

		if (this.position.z > this.endDepth) this.reachedEnd = true;

		this.updateMatrix();
	}
}
