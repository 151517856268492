/*
 *  Attractor
 *  A pool of 3D discs spinning towards the camera
 */

import * as THREE from "three";
import gsap from "gsap";
import AttractorDisc from "./attractor_disc";

export default class Attractor extends THREE.Group
{
	constructor (manager)
	{
		super();

		this.stateName = "ATTRACTOR";
		this.stateMngr = manager.state;
		this.manager = manager;

		this.enabled = false;
		this.active = false;
		this.skipMenu = false;
		this.DISC_COUNT = 10;
		this.START_DEPTH = -2000;
		this.discPool = [];
		this.colours = [];
		this.currentCol = 0;
		this.alpha = 0;

		//Disc material
		this.discMat = new THREE.MeshLambertMaterial();
		this.discMat.envMap = manager.scene.environment;
		this.discMat.reflectivity = 0.2;
		this.discMat.transparent = true;
		this.discMat.opacity = this.alpha;

		manager.add(this);
		manager.addToUpdate(() => this.update(), "attractor");

		//Register with state manager
		manager.state.registerState(this.stateName);
		manager.state.addStateChangeListener((to, from) => this.changeState(to, from));
	}

	//Parse colours & create discs
	init (json, obj, skipMenu = false)
	{
		//Use this if importing GLTF
		// const mesh = obj.scene.getObjectByName("Disc_Flat");
		// this.initNow(json, mesh);

		this.skipMenu = skipMenu;
		this.json = json;

		for (let i = 0; i < obj.children.length; i++)
		{
			if (obj.children[i].isMesh)
			{
				this.initNow(json, obj.children[i]);
				return;
			}
		}
	}

	initNow (json, mesh)
	{
		let device, col;

		//loop through devices
		for (let d = 0; d < json.devices.length; d++)
		{
			device = json.devices[d];

			//Get all colours
			for (let f = 0; f < device.flavours.length; f++)
			{
				col = new THREE.Color();
				col.set(device.flavours[f].color_flavour);

				this.colours.push(col);
			}
		}

		//Shuffle colours
		this.colours.sort(() => Math.random() - 0.5);

		this.createDiscs(mesh);
	}

	changeState(to, from)
	{
		if (to == this.stateName) this.show(1);
		else this.hide();
	}

	show (delay)
	{
		for (let i = 0; i < this.DISC_COUNT; i++)
		{
			this.discPool[i].prewarm();
		}

		//fade in
		gsap.to(this, {
			duration: 1,
			alpha: 1,
			delay: delay,
			onStart: () =>
			{
				this.active = true;
				this.mesh.visible = true;
			},
			onUpdate: () =>
			{
				this.discMat.opacity = this.alpha;
			},
			onComplete: () =>
			{
				this.enabled = true;
			}
		});
	}

	hide ()
	{
		this.enabled = false;

		for (let i = 0; i < this.DISC_COUNT; i++)
		{
			this.discPool[i].hide();
		}

		//fade out
		gsap.to(this, {
			duration: 1,
			alpha: 0,
			onUpdate: () =>
			{
				this.discMat.opacity = this.alpha;
			},
			onComplete: () =>
			{
				this.active = false;
				this.mesh.visible = false;
			}
		});
	}

	update ()
	{
		if (!this.active) return;

		let flagOpacityChange = false;
		let flagColorChange = false;
		let disc, col;

		for (let i = 0; i < this.DISC_COUNT; i++)
		{
			disc = this.discPool[i];
			disc.animate();

			if (disc.reachedEnd)
			{
				col = this.getNextColour();
				disc.sendToBack(col);
			}

			if (disc.opacityFlag)
			{
				this.instanceOpacity[i] = disc.getOpacity();
				flagOpacityChange = true;
			}

			if (disc.colorFlag)
			{
				this.instanceColors[(i * 3) + 0] = disc.getRed();
				this.instanceColors[(i * 3) + 1] = disc.getGreen();
				this.instanceColors[(i * 3) + 2] = disc.getBlue();

				flagColorChange = true;
			}

			this.mesh.setMatrixAt(i, disc.matrix);
		}

		//Update text opacities
		if (flagOpacityChange)
		{
			this.geom.setAttribute("instanceOpacity", new THREE.InstancedBufferAttribute(new Float32Array(this.instanceOpacity), 1));
		}

		//Update colors
		if (flagColorChange)
		{
			this.geom.setAttribute("instanceColor", new THREE.InstancedBufferAttribute(new Float32Array(this.instanceColors), 3));
		}

		this.mesh.instanceMatrix.needsUpdate = true;
	}

	getNextColour ()
	{
		this.currentCol++;
		if (this.currentCol >= this.colours.length) this.currentCol = 0;

		return this.colours[this.currentCol];
	}

	createDiscs (mesh)
	{
		this.geom = mesh.geometry.clone();

		let disc;
		this.instanceColors = [];
		this.instanceOpacity = [];

		for (let i = 0; i < this.DISC_COUNT; i++)
		{
			this.instanceColors.push(1);
			this.instanceColors.push(1);
			this.instanceColors.push(1);

			this.instanceOpacity.push(1.0);

			disc = new AttractorDisc(i, this.START_DEPTH, this.DISC_COUNT, this.manager.camera);
			disc.setColor(this.getNextColour());
			this.discPool.push(disc);

			// this.add(disc);
		}

		//Add colours array to geometry attributes
		this.geom.setAttribute("instanceColor", new THREE.InstancedBufferAttribute(new Float32Array(this.instanceColors), 3));

		this.geom.setAttribute("instanceOpacity", new THREE.InstancedBufferAttribute(new Float32Array(this.instanceOpacity), 1));

		//Modify disc material to add instance properties
		this.discMat.onBeforeCompile = shader => this.modifyShader(shader);

		//Create mesh
		this.mesh = new THREE.InstancedMesh(this.geom, this.discMat, this.DISC_COUNT);

		this.add(this.mesh);
	}

	//These are only applied when DEBUG is true
	bindEvents ()
	{
		document.addEventListener("mousedown", evt => this.onTouch(evt), false);
	}

	onTouch (evt)
	{
		evt.stopPropagation();
		if (!this.enabled) return;
		if (!this.active) return;

		if (this.skipMenu)
		{
			//Get the first available device
			let deviceId = this.json.devices[0].id;
			let brandId = this.json.id;
			let obj = {"deviceId": deviceId, "brandId": brandId};

			//Change device
			this.stateMngr.publishEvent("go_to_product", obj);
			this.enabled = false;

			return;
		}

		//Go to Device Menu
		this.stateMngr.changeState(this.stateMngr.states.DEVICE_MENU);
		this.enabled = false;
	}


	modifyShader (shader)
	{
		const commonVertexChunk = [
			'attribute vec3 instanceColor;',
			'attribute float instanceOpacity;',
			'varying vec3 vInstanceColor;',
			'varying float vInstanceOpacity;',
			'#include <common>'
		].join( '\n' );

		const beginVertexChunk = [
			'#include <begin_vertex>',
			'vInstanceColor = instanceColor;',
			'vInstanceOpacity = instanceOpacity;'
		].join( '\n' );


		const commonFragChunk = [
			'varying vec3 vInstanceColor;',
			'varying float vInstanceOpacity;',
			'#include <common>'
		].join( '\n' );

		const diffuseFragChunk = [
			'vec4 diffuseColor = vec4(diffuse * vInstanceColor, vInstanceOpacity * opacity);'
		].join( '\n' );


		shader.vertexShader = shader.vertexShader
			.replace( '#include <common>', commonVertexChunk )
			.replace( '#include <begin_vertex>', beginVertexChunk );

		shader.fragmentShader = shader.fragmentShader
			.replace( '#include <common>', commonFragChunk )
			.replace( 'vec4 diffuseColor = vec4( diffuse, opacity );', diffuseFragChunk );
	}
}
