/*
 *  BAT Flavour Wall
 *  This is the root module that creates the flavour wall
 */

import SceneManager from "./manager";
import StateManager from "./state_manager";
import AssetLoader from "./preloader";

import Attractor from "./attractor";
import DeviceMenu from "./device_menu";
import FlavoursView from "./flavours_view";

import Console from "./utils/console";
import Stats from "./utils/stats";

import assets from "../json/assets.js";

export default class FlavourWall
{
	constructor (debug)
	{
		if (debug)
		{
			this.stats = new Stats();
			this.logger = new Console();
			this.DEBUG = true;
		}

		//Find canvas
		this.canvas = document.querySelector("canvas");

		//Create three.js scene manager
		this.manager = new SceneManager(this.canvas);

		//Create preloader
		this.manager.loader = new AssetLoader(assets);

		//Create event manager
		this.manager.state = new StateManager();
		this.manager.states = this.manager.state.states;
		//Special event for one product only
		this.manager.state.addEventListener("go_to_product", (id) => this.go_to_product(id));

		//Create defaults
		this.brands = ["vype"];
		this.currentBrand = "vype";
		this.brandData = {};
		this.devicesJson = [];
		this.brandLookup = {};

		//Get root html element
		this.root = document.querySelector("#bat_UI");
		this.root.classList.add("vype");

		//Create states
		this.attractor = new Attractor(this.manager);
		this.deviceMenu = new DeviceMenu(this.manager);
		this.flavours = new FlavoursView(this.manager);

		//Properties
		this.isLoaded = false;
		this.isActive = false;

		this.bindEvents();
		this.resize();
	}

	init (json)
	{
		return new Promise((resolve, reject) => {
			if (typeof json === "string") json = JSON.parse(json);

			if (!json)
				this.manager.loader.addToQueue(
					"content",
					"./json/de_flavourwall_stripped.json",
					"json"
				);
			else this.manager.loader.addAsset("content", json);

			this.manager.loader.load(() => this.initApp(resolve));
		});
	}

	initApp (resolve)
	{
		const json = this.manager.loader.getAsset("content");
		const disc = this.manager.loader.getAsset("disc");
		const posi = this.manager.loader.getAsset("discs_pos");
		this.manager.country = json.market_details.country;

		const brand = "vype";
		const devices = this.sortDevices(json[brand][0].devices);

		//
		const devicesObj = {};
		devicesObj.vype = this.deviceMenu.devicesView.carts;

		//For a single product, we skip the menu & hide the home button
		let skipMenu = false;
		if (devices.length === 1) 
		{
			skipMenu = true;
			this.flavours.hideHome();
		}

		//Initialise states
		this.attractor.init(json[brand][0], disc, skipMenu);
		this.deviceMenu.init(json.pages.landing, devices);
		this.flavours.brandDevices = devicesObj;
		this.flavours.init(json, devices, posi, disc);

		this.isLoaded = true;

		console.info("FlavWall v1.6.2");

		resolve();

		if (this.DEBUG) this.start();
		if (this.DEBUG) this.createDebugMenu();
	}

	start ()
	{
		console.log("Launching WebGL app...");

		this.go_to_age_screener();
		this.render();

		this.isActive = true;
	}

	stop ()
	{
		this.stopRender();

		this.isActive = false;
	}

	go_to_age_screener ()
	{
		this.manager.state.changeState(this.manager.states.ATTRACTOR);
		// this.manager.state.changeState(this.manager.states.DEVICE_MENU);
		// this.manager.state.changeState(this.manager.states.FLAVOURS_VIEW);
	}

	go_to_menu ()
	{
		this.manager.state.changeState(this.manager.states.DEVICE_MENU);
	}

	go_to_product (deviceObj)
	{
		console.log("Changing brand to " + deviceObj.brandId);

		//Change html classes
		this.canvas.classList.remove(this.currentBrand);
		this.root.classList.remove(this.currentBrand);
		this.canvas.classList.add(deviceObj.brandId);
		this.root.classList.add(deviceObj.brandId);
		this.currentBrand = deviceObj.brandId;

		//Make sure device is visible
		this.deviceMenu.showSpecial(deviceObj);

		//Send device change event
		this.manager.state.publishEvent("changeDevice", {"deviceId": deviceObj.deviceId, "brandId": deviceObj.brandId});
		this.manager.state.publishEvent("analytics", {"event": "product", "data": deviceObj.deviceId});

		this.manager.state.changeState(this.manager.states.FLAVOURS_VIEW);
	}

	//Fires callback(analyticsObj) on certain interactions
	//analyticsObj = {"event":"string", "data":"string"}
	registerAnalyticsEvent (callback)
	{
		this.manager.state.addEventListener("analytics", callback);
	}

	//If you want to add Stats outside of DEBUG mode, use this
	addStats ()
	{
		this.stats = new Stats();
	}

	//If you want to add Stats outside of DEBUG mode, use this
	addConsole ()
	{
		this.console = new Console();
	}

	sortDevices (devicesJson)
	{
		const num = devicesJson.length;
		if (num === 1) return devicesJson;

		const devices = [num];

		for (let i = 0; i < num; i++) {
			devices[devicesJson[i].order - 1] = devicesJson[i];
		}

		return devices;
	}

	bindEvents ()
	{
		this.resize = this.resize.bind(this);

		removeEventListener("resize", this.resize);
		removeEventListener("orientationchange", this.resize);

		addEventListener("resize", this.resize);
		addEventListener("orientationchange", this.resize);

		this.manager.bindEvents();
		if (this.DEBUG) this.attractor.bindEvents();
	}

	resize ()
	{
		let w = this.canvas.parentElement.offsetWidth;
		let h = this.canvas.parentElement.offsetHeight;
		if (w === undefined) w = innerWidth;
		if (h === undefined) h = innerHeight;
		if (this.DEBUG) w = innerWidth;
		if (this.DEBUG) h = innerHeight;

		this.canvas.width = w;
		this.canvas.height = h;

		this.manager.onResize(w, h);

		if (!this.manager.landscape)
			this.root.classList.add("portrait");
	}

	render ()
	{
		if (this.isActive) this.manager.onUpdate();
		if (this.stats) this.stats.render(this.manager.renderer);

		this.animId = requestAnimationFrame(() => this.render());
	}

	stopRender ()
	{
		if (this.animId) cancelAnimationFrame(this.animId);
	}



	//For debug purposes
	createDebugMenu ()
	{
		const btn = document.createElement("div");
		btn.addEventListener("mousedown", evt => this.onDebugTouch(evt), false);
		btn.innerHTML = "Attractor";
		btn.style.position = "absolute";
		btn.style.padding = "20px";
		btn.style.bottom = "10px";
		btn.style.right = "10px";
		btn.style.color = "#fff";
		btn.style.border = "1px solid red";
		this.root.appendChild(btn);
	}

	onDebugTouch (evt)
	{
		this.go_to_age_screener();
	}
}
